import React from 'react';
import { Box, Flex, Image, Text, Button, Close } from 'theme-ui';

export default function StoryModal({ photo, title, body, createdAt, onClose }) {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: ['100%', '80%', '60%'],
          maxHeight: '80vh',
          overflowY: 'scroll',
          backgroundColor: 'background',
          borderRadius: '4px',
          padding: 3,
        }}
      >
        <Button
          onClick={onClose}
          sx={{
            position: 'absolute',
            top: 2,
            right: 2,
          }}
        >
          <Close />
        </Button>
        <Image
          src={photo}
          sx={{
            width: '100%',
            borderRadius: '4px',
            maxHeight: '400px',
            objectFit: 'contain',
          }}
        />
        <Text as="h2" sx={{ mt: 3, mb: 2 }}>
          {title}
        </Text>
        <Box
          sx={{
            padding: 3,
            '& p': {
              fontSize: 2,
              lineHeight: 1.75,
            },
          }}
          dangerouslySetInnerHTML={{
            __html: body,
          }}
        ></Box>
        <Text as="small" sx={{ color: 'gray' }}>
          {createdAt}
        </Text>
      </Box>
    </Box>
  );
}
