import React, { useState } from 'react';
import useBusinessArticles from '../hooks/useBusinessArticles';
import BlogCard from '../components/BlogCard';
import Layout from '../components/Layout';
import { Flex, Box } from 'theme-ui';
import dayjs from 'dayjs';
import StoryModal from '../components/StoryModal';

export default function Blog() {
  const { loading, stories } = useBusinessArticles();
  const [selectedStory, setSelectedStory] = useState(null);

  if (loading) {
    return 'loading...';
  }

  if (!loading && !stories) {
    return 'No Blog found!';
  }

  const getImage = media => {
    if (media.length) {
      return `https://res.cloudinary.com/gonation/f_auto,q_auto,g_face,c_lfill,w_400/${media[0].cloudinaryId}`;
    }
  };

  const displayBlogModal = story => () => {
    setSelectedStory(story);
  };

  const closeModal = () => {
    setSelectedStory(null);
  };

  return (
    <Layout pageTitle="blog">
      <Flex
        sx={{
          maxWidth: 1100,
          mx: 'auto',
          py: [1, 4],
          px: [1, 3],
          flexWrap: 'wrap',
        }}
      >
        {stories.map(story => {
          return (
            <Box
              sx={{
                width: ['100%', '50%', '33.3333%'],
                padding: [1, 3],
              }}
              onClick={displayBlogModal(story)}
            >
              <BlogCard
                key={story.id}
                image={getImage(story.media)}
                title={story.title || story.name}
                date={dayjs(story.createdAt).format('MMMM D, YYYY')}
              />
            </Box>
          );
        })}
      </Flex>
      {selectedStory && (
        <StoryModal
          photo={getImage(selectedStory.media)}
          title={selectedStory.title || selectedStory.name}
          body={selectedStory.body}
          createdAt={dayjs(selectedStory.createdAt).format('MMMM D, YYYY')}
          onClose={closeModal}
        />
      )}
    </Layout>
  );
}
