import { useEffect, useState } from 'react';
import axios from 'axios';

const useBusinessArticles = () => {
  const [stories, setStories] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchArticleData = async () => {
    try {
      const data = await axios.get(
        `https://gonation.com/api/proxy/v2/businesses/bzn-PKzc8iPtRTSo0pG0hel46g/pulseArticles`
      );
      setStories(data.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error('Error happened when fetching article data', e);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchArticleData();
  }, []);

  return { stories, loading };
};

export default useBusinessArticles;
