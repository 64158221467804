import React from 'react';
import { Text, Box, Flex, Image, jsx, Button } from 'theme-ui';

export default function BlogCard({ image, title, blurb, date }) {
  return (
    <Box>
      <Image src={image} sx={{ width: '100%', borderRadius: '4px' }} />
      <Text as="h2" sx={{ mt: 3, mb: 2 }}>
        {title}
      </Text>
      <Text as="small" sx={{ color: 'gray' }}>
        {date}
      </Text>
    </Box>
  );
}
